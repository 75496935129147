import { graphql } from "gatsby";
import React from "react";
import { FaInfo, FaPhone } from "react-icons/fa";
import ContactForm from "../components/ContactForm";
import Container from "../components/container";
import Hero from "../components/HeroBanner";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styles from "./kontakt.module.css";

export const query = graphql`
  query {
    heroBg: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const Kontakt = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Wypróbuj demo CRM przez 30 dni za darmo"
        description="Szukasz 🔎 elastycznego systemu CRM dla swojej firmy? ✅ Porozmawiajmy o potrzebach Twojej firmy. Zapytaj o bezpłatne demo!"
      />
      <Hero heroImage={data.heroBg} justifyContent="center">
        <h1>Kontakt</h1>
        <h2>Masz pytania? Skontaktuj się z nami</h2>
      </Hero>
      <Container className={styles.wrapper}>
        <div className={styles.formWrapper}>
          <div className={styles.contactHeader}>
            <FaInfo size="50px" />
            <p>
              <strong>Masz pytania? Napisz do nas</strong>
            </p>
          </div>
          <ContactForm systemType="empireo" />
        </div>
        <div className={styles.mapContainer}>
          <div className={styles.contactHeader}>
            <FaPhone size="50px" />
            <p>
              <strong>…albo po prostu zadzwoń</strong>
            </p>
            <p>
              <a
                className={styles.phoneNum}
                href="tel:+48506504540"
                title="+48 506 504 540"
              >
                +48 506 504 540
              </a>
            </p>
            <p>
              Pracujemy od poniedziałku do piątku <br />
              w godzinach 8 — 16
              <br />
              Z przyjemnością odpowiemy na Twoje pytania dotyczące <br />{" "}
              systemu Empireo i naszego doświadczenia we wdrożeniach systemów
              IT.
            </p>
          </div>
          <iframe
            title="Adres Questy"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.608052588435!2d16.913769930151982!3d52.413779407663064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47045b4b2800c3e7%3A0x5336529b5602e3de!2sQuesty!5e0!3m2!1spl!2spl!4v1488363170109"
            // style="border: 0px none; pointer-events: none;"
            allowFullScreen=""
            width="400"
            height="300"
            frameBorder="0"
          />
        </div>
      </Container>
    </Layout>
  );
};

export default Kontakt;
